.hamburger-menu{
  display: none;
}

.desktop-only {
  display: none !important;
  @media (min-width: 1200px) {
    display: flex !important;
  }
}
@media (max-width:1200px) {
  .hamburger-menu{
    display: block;
  }



  .checkedspan,
  .checkedspan::before,
  .checkedspan::after {
   display: block;
   position: absolute;
   width: 100%;
   height: 2px;
   background-color: black;
   border-radius: 10%;
   transition-duration: .25s;
 }

.checkedspan {
  transform: rotate(45deg);
}
.checkedspan::before {
  content: '';

  top: 0;
  transform: rotate(0);
}
.checkedspan::after {
  content: '';

  top: 0;
  transform: rotate(90deg);
}


.menu__btn {
  display: flex;
  align-items: center;
  position: fixed;
  top: 20px;
  right: 20px;
  width: 26px;
  height: 26px;
  cursor: pointer;
  z-index: 3;
}

 .span,
 .span::before,
 .span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: black;
  border-radius: 10%;
  transition-duration: .25s;
}
.span::before {
  content: '';
  top: -8px;
}
.span::after {
  content: '';
  top: 8px;
}


.menu__box{
  display: block;
  position: fixed;
  top: 0;
  z-index: 2;
  width: 300px;
  height: 100%;
  max-height: 640px;
  overflow-y: scroll;
  margin: 0;
  padding: 80px 0;
  list-style: none;
  background-color: white;
  box-shadow: 1px 0px 6px rgba(0, 0, 0, .2);
  transition-duration: .25s;
  ul{
  display: block;
  li{
    font-size: 16px;
    line-height: 24px;
    color: black;
    cursor: pointer;
    width: 100%;
    position: relative;
  }
}
}
}

.lang-mobile{
  width: 98px;
  .lang{
    display: block;
    p{
      padding: 0;
      background: transparent;
      color: black;
      width: 53px;
      height: 43px;
    }
  }

  li{
    width: 100%;
    left: 0;
    background: none;
    content: none;
    position: relative;
    &::before{
      border-color: none;
      border-style: none;
    }
  }
}

.mobile-button{
  margin-top:32px;
  margin-bottom:32px;
  padding-left: 32px;
  margin-left: 26px;
  padding: 10px;
.button_header{
    position: relative !important;
  }
}
