$null: 0;

*{
  font-family: 'Poppins', sans-serif;

}

h1{font-size: 96px;line-height: 144px;}
h2{font-size: 64px;font-weight: 600;line-height: 70px;color: #0082F4;}
h3{font-size: 24px;font-weight: 600;line-height: 36px}
h4{font-size: 18px;font-weight: 600;line-height: 27px}

//------------------------------------------------------------HEADER----------------------------------------------------//
header{
  z-index: 2;
  position: absolute;
  background: white;
  width: 100%;
  ul{
    list-style-type: none;
    display: inline-flex;
    li{
      margin: 20px 0;
      margin-right: 0;
      color: #3D3D3D;
      a{
        cursor: pointer;
        color: #3D3D3D;
        &:hover{
          color: white;
          text-decoration: none;
        }
      }
    }
  }
  .button_header{
    text-decoration: none;
    background: linear-gradient(90deg, #00A482 0%, #009B33 100%), linear-gradient(180deg, #44ADFF 0%, #0082F4 100%);
    box-shadow: 0px 0px 6px 3px rgba(68, 173, 255, 0.32);
    border-radius: 6px;
    padding: 16px 32px;
  &:hover{
    background: green;
  }
  }
}

.lang{
  transition: 0.5s;
  color: white;
  display: block !important;
  p{
    padding-top: 11px;
    display: inline-flex;
    color: black;
    opacity: 0.85;
    border-top-left-radius: 5px;
border-top-right-radius: 5px;
padding: 10px;
max-width: 62px;
max-height: 43px;
svg{
  margin-top: 8px;
  margin-left: 4px;
}
  }
  li{
    background: #140A28;
    padding: 5px;
    opacity: 0.85;
    margin: 0;
  }
  div{
    background: linear-gradient(180deg, #469FE3 0%, #085EAA 100%);
    border-radius: 5px;
    margin-left: 5px;
    color: white;
  }
  button{
    color: white;
    cursor: pointer;
    outline: none;
    border: none;
    background: transparent;
  }
}

@media (max-width:1200px) {
  header{
    .lang-desktop,
    .button-start,
    .links{
      display: none;
    }
  }
}

.agreement{
  ul{
    list-style: disc;
    display: block;
  }
}
@media (max-width:1200px) {
  header{
    background: transparent;
    box-shadow: none;
  }
}

.main_logo_desktop,
.main_logo_mobile{
  display: none;
}

@media (min-width:1200px) {
  .main_logo_desktop{
    display: block;
  }
}

@media (max-width:1199px) {
  .main_logo_mobile{
    display: block;
  }
}
//------------------------------------------------------------HEADER----------------------------------------------------//


//------------------------------------------------------------BANNER----------------------------------------------------//
.mobile{
  display: none;
}
.banner_description{
font-size: 24px;
}
@media (max-width:1000px) {
  .banner{
    width: 100%;
    height: 823px;
    background: url("mobile_green.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    .container-banner{
      padding-top: 250px;
      padding-bottom: 350px;
      text-align: center;
    }
    .hover_banner{
      display: inline-flex;
    }
    .banner_description{
      font-size: 24px ;
      padding-left: 10px;
      padding-right: 10px;
    }
    .banner_main_end{
      color: #009D32;
      font-weight: 600;
      font-size: 56px;
    }

    .banner_main_start{
      color: white;
      font-size: 56px;

      font-weight: 700;
      margin-right: 30px;
    }

  }
  .desktop-banner{
    display: none;
  }
  .mobile{
    display: block;
  }
  .slick-dots li{
    width:40px;
  }
.slick-dots li:not(.slick-active) { background: #A0ACB6;height: 6px;border-radius: 20%;
  padding-left: 20px;
  padding-right: 20px;
}

.slick-dots li.slick-active { background: #009D32;height: 6px;border-radius: 20%;
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.slick-dots li button::before{
      font-size: 0;
      line-height: 6px;
      background: #009D32;
      border-radius: 20%;
      padding-left: 20px;
      height: 6px;
      padding-right: 20px;
    }
.slick-next{
  right: 0;
  display: none !important;
}
}

.slider_banner_mobile{
  margin-top: -250px;
}

.mobileCard{
  margin-bottom: 30px;
  padding-top: 32px;
  background: #FFFFFF;
  box-shadow: 0px 8px 16px rgba(34, 125, 195, 0.15);
  border-radius: 15px;
  min-height: 331px;
  & img{
    display: inline-flex;
  background: rgba(68, 173, 255, 0.3);
    border-radius: 5px;
  }
//  &:hover{
//    transition: 0.3s;
//    color: white;
//    background: linear-gradient(180deg, #44ADFF 0%, #0081F0 100%);
//    &:hover img{
//      background: white;
//      border-radius: 5px;
//    }
//}
}
@media (min-width:501px) {
  .banner{
    width: 100%;
    height: auto;
    background: url("banner_green.jpg");
    background-size: 100% 100%;
    background-attachment: fixed;
    background-repeat: no-repeat;
    .container-banner{
      padding-top: 250px;
      padding-bottom: 350px;
      text-align: center;
    }
      .hover_banner{
      display: inline-flex;
    }
  }
}

.banner_main_end{
  color: #009D32;
  font-weight: 600;
}

.banner_main_start{
  color: white;
  font-weight: 700;
  margin-right: 30px;
}

.button_banner{
padding: 16px 32px;
background: linear-gradient(90deg, #00A482 0%, #009B33 100%);
box-shadow: 0px 0px 24px 3px rgba(4, 132, 45, 0.33);
border-radius: 6px;
&:hover{
  text-decoration: none;
  background: green;
}
}

.card-banner{
  border-radius: 15px;
  padding-top: 32px;
  min-height: 337px;
  background: white;
  margin-top: -150px;
  text-align: center;
  color: #3D3D3D;
  width: 276px;
position: absolute;
box-shadow: 0px 8px 16px rgba(34, 125, 195, 0.15);
& img{
  background: #E9F8EF;
  border-radius: 5px;
}
//&:hover{
//  transition: 0.3s;
//  color: white;
//  background: linear-gradient(180deg, #44ADFF 0%, #0081F0 100%);
//  &:hover img{
//    background: white;
//    border-radius: 5px;
//  }
//}
  p{

    font-size: 16px;
    line-height: 24px;
  }
}

@media (max-width:1200px) {
  .card-banner{
    width: 100%;
  }
}

@media (max-width:1000px) {
  .banner{
    .col-md-3{
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
//---------------------------------------------------------------------------ABOUT------------------------------------//

.about{
  padding-top: 350px;
  padding-bottom: 200px;
  h2{
    margin-bottom: 80px;
  }
.container-about{
  cursor: pointer;
  border: 1px solid #E5E5E5;
  border-radius: 10px;
  padding: 28px;

  p{
    font-size: 14px;
    font-weight: 400;
    line-height: 27px;
    color: #3D3D3D;
  }
  .status{
    width: 24px;
    color: #009D32;
    height: 24px;
    top: 34px;
    position: absolute;
    right: 35px;
    text-align: center;
    border-radius: 100%;
    padding: 2px;
    border: 2px solid #009D32;
    p{
      font-weight: bold;
      color: #009D32;
      margin-bottom: 0;
      margin-top: -5px;
    }
    &:hover{
      cursor: pointer;
      transition: 0.5s;
      background:  #009D32;
      p{
        color: white;
      }
    }
  }
}
}

.button_about{
padding: 10px 37px;
color: white;
width: 180px;
background: linear-gradient(90deg, #00A482 0%, #009B33 100%);
box-shadow: 0px 0px 6px 3px rgba(68, 173, 255, 0.32);
border-radius: 6px;
color: white;
&:hover{
  text-decoration: none;
  background: green;
}
}

.about{
  .mobile-panel{
    margin-right: 32px;
    padding: 0;
  li{
    cursor: pointer;
    height: 42px;
    margin-top: 16px;
    padding: 10px;
    border-radius: 10px;
    background: transparent;
    border: 1px solid #E5E5E5;
    &:hover{
      background: linear-gradient(180deg, #44ADFF 0%, #0081F0 100%), #FFFFFF;
    }
  }
}
}
.mobile-content-area{
  padding-left: 10px;
  padding-right:10px;
  text-align: initial;
  h3{
    color: #009D32;
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
  }
  p{
    font-size: 14px;
    line-height: 21px;
    color: #3D3D3D;
  }
}

@media (min-width:600px) {
  .mobile-panel,
  .mobile-content-area{
    display: none;
  }
}

@media (max-width: 600px) {
  .slider-desktop,
  .button_about{
    display: none;
  }
  .about{
    h2{
    font-size: 35px;
    }
  }
}
//---------------------------------------------------------------------------WHAT NEW------------------------------------//

.what-new{
  h2{color: #009D32;}
  width: 100%;
  background: #E9F8EF;
  padding-top: 100px;
  padding-bottom: 100px;
  .hover_exchange{
    color: #1C1C1D;
  }
  .description_exchange{
    font-size: 21px;
    font-weight: 400px;
    line-height: 31,5px;
    color: #3D3D3D;
  }
  .button_what_new{
    margin-top: 10px;
    position: absolute;
    color: white;
    text-decoration: none;
    padding: 16px 32px;
    background: linear-gradient(90deg, #00A482 0%, #009B33 100%);
    box-shadow: 0px 0px 6px 3px rgba(68, 173, 255, 0.32);
    border-radius: 6px;
    &:hover{
      background: green;
    }
  }
  .table-currency{
    border-radius: 30px;
    box-shadow: 0px 8px 16px rgba(34, 125, 195, 0.15);
    background: white;
    padding-top: 62.5px;
    padding-bottom: 62.5px;
    color: #3D3D3D;
  }
  .type{
    font-size: 23px;
    font-weight: 600;
    color: #2F3D60;
  }
  p{
    font-size: 14px;
    color: #1C1C1D;
  .percent{
    font-size: 14px;
    font-weight: bold;
    display: inline-flex;
    color: #009D32;
    margin-bottom: 0;
  }
}
.col-md-5 img{
  float: right;
}
}
.mobileSliderCurrency{
  .col-md-12{
    width:88% !important;
  }
display: none;
img{
  margin-bottom: 10px;
}
p,
.percent{
  font-size: 18px !important;
}
//.slick-slide, .slick-active, .slick-current{width:300px !important}
}
.limit-row{
  padding-top: 100px;
}


@media (max-width:600px) {
  .what-new{
    h2{
      font-size: 42px;
    }
    text-align: center;
      .button_what_new{
        display: block ruby;
        width: 100%;
        position: relative;
        margin-bottom: 116px;
      }
      .hover_exchange{
        margin-top: 32px;
        margin-bottom: 10px;
      }
      .rates{
        display: none;
      }
      .col-md-5 img{
        text-align: center;
        justify-content: center;
        float: none;
      }
      .mobileSliderCurrency{
        display: block;
      }
      .table-currency{
        display: none;
      }
      .limit-row{
        padding-top: 30px;
      }
      .desktop{
        display: none;
      }
      .slick-dots,
      .slick-arrow{
        display: none !important;
      }
  }
}

//---------------------------------------------------------------------------EXCHANGE------------------------------------//

.exchange{
  padding-top: 100px;
  padding-bottom: 150px;
  p{
    font-size: 36px;
    color: #3D3D3D;
    a{
      color: #009D32;
      text-decoration: none;
      font-weight: bold;
    }
  }
  .button_exchange{
      color: white;
      width: 217px;
      text-decoration: none;
      padding: 15px;
      padding-left: 29px;
      padding-right: 29px;
      height: 47px;
      background: -webkit-linear-gradient(left, #00A482 0%, #009B33 100%);
      background: -o-linear-gradient(left, #00A482 0%, #009B33 100%);
      background: linear-gradient(to right, #00A482 0%, #009B33 100%);
      box-shadow: 0px 8px 16px rgba(5, 77, 28, 0.1);
      border-radius: 6px;
      &:hover{
       background: green;
      }
    }
}

@media (max-width:600px) {
  .exchange{
    p{
      text-align: center;
    }
    .button_exchange{
      display: block ruby;
      width: 100%;
      height: 100%;
      padding-top: 15px;
      padding-bottom: 15px;
      margin-top: 30px;
  }
}
}
//---------------------------------------------------------------------------SUPPORT------------------------------------//
.support{
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
background: #F8F9FA;
p{
  color: #3D3D3D;
}
h2{
  font-size: 42px;
  color: #009D32;
  line-height: 72px;
}
.quess_hover{
  font-size: 18px;
  color: #44ADFF;
}
.quess_description{
  color: #3D3D3D;
  font-size: 24px;
  &:hover{
    text-decoration: none;
  }
}
.img-support{
  float: right;
}
}



@media (max-width:770px) {
  .support{
  .col-md-6{
    text-align: center;
    justify-content: center;
    .img-support{
      float: none;
    }
    .row{
      justify-content: center;
    }
  }
  }
}
//------------------------------------------------------------FOOTER----------------------------------------------------//


footer{
  img{
    width: 74px;
  }
  .footer-inline-start,
.footer-inline,
.footer-inline-end{
  display: inline-flex;
}
 padding: 50px 0;
  width: 100%;
  min-height: 173px;
  background: linear-gradient(166.9deg, #192742 -18.08%, #205830 192.05%);
  .footer_hover{
font-size: 12px;
color: #44ADFF;
font-style: normal;
font-weight: 400;
text-align: left;
  }
  .footer_description{
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
color: white;
&:hover{
  text-decoration: none;
}
  }
  .copyright{
    padding: 3px 0;
  color: white;
  float: right;
    font-style: normal;
font-weight: normal;
font-size: 14px;
  }
}
ul{
  float: left;
  list-style: none;
  display: inline-flex;
  li>a{
    color: #0CBF47;
    margin-right: 32px;
    font-size: 14px;
    &:hover{
      text-decoration: none;
      color: green;
    }
  }
}

@media (min-width: 601px) and (max-width: 1200px) {
  .col-md-7>ul{
    float: right;
    li>a{
      margin-right: 0;
      margin-left:32px;
    }
  }
}
@media (min-width:616px) {
  .footer-inline-start{
  margin-left: 30px;
  margin-bottom: 20px;
  }
}


@media (max-width:600px) {
  footer{
    text-align: center;
    img{
      width: 143px;
      margin-bottom: 47px;
    }
    .col-md-2,
    .col-md-6{
      text-align: center;
    }
    .copyright{
      margin-top: 64px;
      float: none;
    }
    .footer-inline-end,
    .footer-inline-start{
      display: block;
      text-align:center;
    }
    .footer-inline-start{
      margin-bottom: 35px;
    }
  }
  ul{
    display: block;
    float: none;
  }
}

table{
  width: 100%;
  box-shadow: 0px 8px 16px rgba(34, 125, 195, 0.15);
border-radius: 15px;
thead{
  width: 100%;
  background: #EDEEEF;
  th{
    font-style: normal;
font-weight: bold;
font-size: 18px;
    padding: 14px;
  }
}

tbody{
  width: 100%;

  th{
    border-bottom: 1px solid #EDEEEF;
    padding: 14px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
  }
}
}
.MobileChooserButton{
   outline: 0 !important;
  width: 48px;
  height: 48px;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid #E5E5E5;
  border-radius: 10px;
}

.about-content-img{
  width: 245px;
  height: 490px;
  border-radius: 10px;
  margin-bottom: 40px;
  filter: drop-shadow(0px 12px 24px rgba(34, 125, 195, 0.2));
}

@media (max-width: 600px) {
  .about-content-img{
    width: 179px;
    height: 380px;
  }
}

.scrollContainer{
  background: #009D32;
  position: fixed;
  padding: 14px;
  bottom: 10px;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid white;
  right: 10px;
  z-index: 1;
}
